// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.failed-page {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; /* Full height of the viewport */
    background-color: #ffffff; /* Light red background for failed state */
    color: #000000; /* Dark red text color */
    font-family: Arial, sans-serif;
}

.failed-container {
    text-align: center;
    padding: 20px;
    border-radius: 8px;
    background-color: #58839f; /* White background for the container */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.failed-container h2 {
    font-size: 24px;
    margin-bottom: 10px;
}

.failed-container p {
    font-size: 16px;
    margin-bottom: 20px;
}

.home-button {
    padding: 10px 20px;
    font-size: 16px;
    color: #fff;
    background-color: #dc3545; /* Bootstrap's danger color */
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.home-button:hover {
    background-color: #dc3545; /* Darker red on hover */
}
`, "",{"version":3,"sources":["webpack://./src/PaymentFailed.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,aAAa,EAAE,gCAAgC;IAC/C,yBAAyB,EAAE,0CAA0C;IACrE,cAAc,EAAE,wBAAwB;IACxC,8BAA8B;AAClC;;AAEA;IACI,kBAAkB;IAClB,aAAa;IACb,kBAAkB;IAClB,yBAAyB,EAAE,uCAAuC;IAClE,wCAAwC;AAC5C;;AAEA;IACI,eAAe;IACf,mBAAmB;AACvB;;AAEA;IACI,eAAe;IACf,mBAAmB;AACvB;;AAEA;IACI,kBAAkB;IAClB,eAAe;IACf,WAAW;IACX,yBAAyB,EAAE,6BAA6B;IACxD,YAAY;IACZ,kBAAkB;IAClB,eAAe;IACf,iCAAiC;AACrC;;AAEA;IACI,yBAAyB,EAAE,wBAAwB;AACvD","sourcesContent":[".failed-page {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    height: 100vh; /* Full height of the viewport */\n    background-color: #ffffff; /* Light red background for failed state */\n    color: #000000; /* Dark red text color */\n    font-family: Arial, sans-serif;\n}\n\n.failed-container {\n    text-align: center;\n    padding: 20px;\n    border-radius: 8px;\n    background-color: #58839f; /* White background for the container */\n    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);\n}\n\n.failed-container h2 {\n    font-size: 24px;\n    margin-bottom: 10px;\n}\n\n.failed-container p {\n    font-size: 16px;\n    margin-bottom: 20px;\n}\n\n.home-button {\n    padding: 10px 20px;\n    font-size: 16px;\n    color: #fff;\n    background-color: #dc3545; /* Bootstrap's danger color */\n    border: none;\n    border-radius: 5px;\n    cursor: pointer;\n    transition: background-color 0.3s;\n}\n\n.home-button:hover {\n    background-color: #dc3545; /* Darker red on hover */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
