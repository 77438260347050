



import React from 'react';
import ReactDOM from 'react-dom/client'; // Updated import for createRoot
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import App from './App';
import PaymentPage from './PaymentPage';
import CustomerPage from './CustomerInfoModal';
import PaymentSuccess from './PaymentSuccess';
import PaymentFailed from './PaymentFailed';
import './index.css';

const rootElement = document.getElementById('root');
const root = ReactDOM.createRoot(rootElement); // Create a rootsss

root.render(
  <Router>
    <Routes>
      <Route path="/" element={<App />} />
      <Route path="/customer-info" element={<CustomerPage />} />
      <Route path="/payment" element={<PaymentPage />} />
     <Route path="/payment-success" element={<PaymentSuccess />} />
      <Route path="/payment-failed" element={<PaymentFailed />} />
    </Routes>
  </Router>
);
