// src/PaymentFailedPage.js
import React from 'react';
import './PaymentFailed.css';

const PaymentFailedPage = () => {
    return (
        <div className="failed-page">
            <div className="failed-container">
                <h2>Payment Failed</h2>
                <p>Unfortunately, your payment could not be processed. Please try again.</p>
                <button className="home-button" onClick={() => window.location.href = '/'}>
                    Go to Home
                </button>
            </div>
        </div>
    );
};

export default PaymentFailedPage;
