// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.App {
  text-align: center;
  font-family: Arial, sans-serif;
}

.App-header {
  background-color: #282c34;
  padding: 5px;
  color: white;
}

.plans-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding: 1px;
}

.plan-card {
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 10px;
  margin: 10px;
  padding: 10px;
  width: 150px;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  text-align: left;
}

.plan-card h2 {
  font-size: 1.5em;
  margin-bottom: 0.5em;
}

.plan-price {
  font-size: 2em;
  color: #333;
}

.plan-agents {
  font-size: 1em;
  color: #777;
  margin: 1em 0;
}

.plan-button {
  background-color: #007bff;
  border: none;
  color: white;
  padding: 10px 20px;
  font-size: 1em;
  cursor: pointer;
  margin-bottom: 1em;
  border-radius: 4px;
}

.plan-button:hover {
  background-color: #0056b3;
}

.plan-features {
  list-style: none;
  padding: 0;
}

.plan-features li {
  margin: 0.5em 0;
  display: flex;
  align-items: center;
}

.plan-features li::before {
  content: "✔";
  color: green;
  margin-right: 0.5em;
}

`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,8BAA8B;AAChC;;AAEA;EACE,yBAAyB;EACzB,YAAY;EACZ,YAAY;AACd;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,eAAe;EACf,YAAY;AACd;;AAEA;EACE,gBAAgB;EAChB,sBAAsB;EACtB,mBAAmB;EACnB,YAAY;EACZ,aAAa;EACb,YAAY;EACZ,qCAAqC;EACrC,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;EAChB,oBAAoB;AACtB;;AAEA;EACE,cAAc;EACd,WAAW;AACb;;AAEA;EACE,cAAc;EACd,WAAW;EACX,aAAa;AACf;;AAEA;EACE,yBAAyB;EACzB,YAAY;EACZ,YAAY;EACZ,kBAAkB;EAClB,cAAc;EACd,eAAe;EACf,kBAAkB;EAClB,kBAAkB;AACpB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,gBAAgB;EAChB,UAAU;AACZ;;AAEA;EACE,eAAe;EACf,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,YAAY;EACZ,YAAY;EACZ,mBAAmB;AACrB","sourcesContent":[".App {\n  text-align: center;\n  font-family: Arial, sans-serif;\n}\n\n.App-header {\n  background-color: #282c34;\n  padding: 5px;\n  color: white;\n}\n\n.plans-container {\n  display: flex;\n  justify-content: center;\n  flex-wrap: wrap;\n  padding: 1px;\n}\n\n.plan-card {\n  background: #fff;\n  border: 1px solid #ddd;\n  border-radius: 10px;\n  margin: 10px;\n  padding: 10px;\n  width: 150px;\n  box-shadow: 0 4px 8px rgba(0,0,0,0.1);\n  text-align: left;\n}\n\n.plan-card h2 {\n  font-size: 1.5em;\n  margin-bottom: 0.5em;\n}\n\n.plan-price {\n  font-size: 2em;\n  color: #333;\n}\n\n.plan-agents {\n  font-size: 1em;\n  color: #777;\n  margin: 1em 0;\n}\n\n.plan-button {\n  background-color: #007bff;\n  border: none;\n  color: white;\n  padding: 10px 20px;\n  font-size: 1em;\n  cursor: pointer;\n  margin-bottom: 1em;\n  border-radius: 4px;\n}\n\n.plan-button:hover {\n  background-color: #0056b3;\n}\n\n.plan-features {\n  list-style: none;\n  padding: 0;\n}\n\n.plan-features li {\n  margin: 0.5em 0;\n  display: flex;\n  align-items: center;\n}\n\n.plan-features li::before {\n  content: \"✔\";\n  color: green;\n  margin-right: 0.5em;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
