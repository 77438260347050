


import React, { useState } from 'react';
import './PaymentPage.css';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';


const PaymentPage = () => {
  const [userCount, setUserCount] = useState(1);
  const location = useLocation();
  const navigate = useNavigate();
  const { planName, monthlyPrice, annuallyPrice } = location.state || {};

  // Log location state to debug
  console.log('Location State:', location.state);

  // Remove currency symbol and convert to number
  const monthlyRate = Number(monthlyPrice.replace(/₹|,/g, '').trim()) || 0;
  const annualRate = Number(annuallyPrice.replace(/₹|,/g, '').trim()) || 0;

  const [billingCycle, setBillingCycle] = useState('Annual');
  const [selectedFeatures, setSelectedFeatures] = useState([]);

  const features = [
    { id: 1, name: 'Advanced AI', price: 100.00 }, // Price in INR
    { id: 2, name: 'ChatBot', price: 200.00 },
  
  ];

  const totalPrice = (billingCycle === 'Monthly' ? monthlyRate : annualRate) * userCount +
                     selectedFeatures.reduce((sum, feature) => sum + feature.price, 0);

  const handleUserCountChange = (increment) => {
    setUserCount((prevCount) => Math.max(1, prevCount + increment));
  };

  const handleBillingCycleChange = (cycle) => {
    setBillingCycle(cycle);
  };

  const toggleFeature = (feature) => {
    setSelectedFeatures((prevSelected) => {
      // Check if the feature is already selected based on its id
      if (prevSelected.some(f => f.id === feature.id)) {
        // If the feature is selected, remove it
        return prevSelected.filter(f => f.id !== feature.id);
      } else {
        // If the feature is not selected, add it
        return [...prevSelected, feature];
      }
    });
  };
  

  const handleSubmit = (e) => {
    e.preventDefault();
    axios.post('https://subscription.tech-bridge.biz:8181/api/phonepay/payment', {
      totalPrice: totalPrice
    })
    .then(response => {
      console.log('Payment Response:', response.data); // Log the response
      window.location.href = response.data;
     

    })
    .catch(error => {
      console.error('Error processing payment:', error);
    });
  };

  return (
    <div className="payment-page" style={{ backgroundColor: "#ffffff" }}>
      <div className="payment-details">
        <h1 style={{marginLeft: "30px"}}>Your Products</h1>
        {planName ? (
          <p style={{marginLeft: "30px", fontWeight: 'bold' , marginTop: "45px"}}>You have selected the <strong>{planName}</strong> plan.</p>
        ) : (
          <p style={{marginLeft: "30px",fontWeight: 'bold' , marginTop: "45px"}}>No plan selected.</p>
        )}
       <div className="plan-details" style={{ }}>
  <p style={{ marginLeft: "30px"  }}>Select the number of users</p>
  <div className="user-count" style={{ marginLeft: "30px", alignItems: 'center', justifyContent: 'center', padding: '10px',border: "2px solid #0b72b9",  borderRadius: '8px',width:"88px",height: "20px" }}>
    <button onClick={() => handleUserCountChange(-1)} style={{ fontSize: '18px', padding: '10px 15px', margin: '0 10px', borderRadius: '5px', backgroundColor: '#007bff', color: '#fff', border: 'none', cursor: 'pointer' }}>-</button>
    <span style={{ fontSize: '20px', fontWeight: 'bold' }}>{userCount}</span>
    <button onClick={() => handleUserCountChange(1)} style={{ fontSize: '18px', padding: '10px 15px', margin: '0 10px', borderRadius: '5px', backgroundColor: '#007bff', color: '#fff', border: 'none', cursor: 'pointer' }}>+</button>
  </div>
</div>

<div className="billing-cycle">
  <p style={{ marginLeft: "30px", fontWeight: 'bold', marginTop: "20px", fontSize: '24px' }}>
    Select a subscription cycle
  </p>
  <div className="cycle-options" style={{ marginLeft: "30px", width: "45vw", height: "20vh", display: 'flex', gap: '30px' }}>
    <button
      className={billingCycle === 'Monthly' ? 'active' : ''}
      onClick={() => handleBillingCycleChange('Monthly')}
      style={{ width: "20vw", height: "20vh", padding: "10px", boxSizing: "border-box", display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}
    >
      <strong style={{ fontSize: '22px' }}>Monthly</strong>
      <strong style={{ fontSize: '22px' }}>₹{monthlyRate.toFixed(2)}</strong>
      <span style={{ fontSize: '16px' }}>/month/user</span>
    </button>

    <button
      className={billingCycle === 'Annual' ? 'active' : ''}
      onClick={() => handleBillingCycleChange('Annual')}
      style={{ width: "20vw", height: "20vh", padding: "10px", boxSizing: "border-box", display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}
    >
      <strong style={{ fontSize: '22px' }}>Annual (Save 15%)</strong>
      <strong style={{ fontSize: '22px' }}>₹{annualRate.toFixed(2)}</strong>
      <span style={{ fontSize: '16px' }}>/year/user</span>
    </button>
  </div>
</div>



   <hr style={{marginTop: "50px"}}/>     
        {/* Additional Features Section */}
      {/* Additional Features Section */}
      <div className="additional-features">
          <h2 style={{ marginLeft: "30px"}}>Enhance Your Subscription Experience</h2>
          {features.map((feature) => (
            <div key={feature.id} className="feature-option" style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }} >
              <span  style={{ marginLeft: "30px",fontSize: "20px"}}>{feature.name} (₹{feature.price}) </span>
              <button 
                onClick={() => toggleFeature(feature)}
                style={{ marginLeft: '10px', padding: '5px 10px', backgroundColor: '#0b72b9', color: '#fff', border: 'none', borderRadius: '5px', cursor: 'pointer',marginRight: "130px" }}
              >
                <span >Add On</span>
              </button>
            </div>
          ))}
        </div>
      </div>

      <div className="order-summary"  style={{ padding: '30px' }}>
        <h1 style={{ marginLeft: "20px"}}>Order Summary</h1>
       
<div className="summary-item" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: "10px", backgroundColor: '#F0F0F0' }}>
  <p style={{ marginLeft: "20px", fontWeight: 'bold' }}>{planName}</p>
  <p style={{ marginRight: "70px" }}>₹{(billingCycle === 'Monthly' ? monthlyRate : annualRate).toFixed(2) * userCount}</p>
</div>


{selectedFeatures.map((feature) => (
  <div className="summary-item" key={feature.id} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: "10px",backgroundColor: '#E8E8E8' }}>
    <p style={{ marginLeft: "20px", fontWeight: 'bold' }}>{feature.name}</p>
    <p style={{ marginRight: "70px" }}>₹{feature.price}</p>
  </div>
))}


<div className="total" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '10px', backgroundColor: '#B0B0B0' }}>
  <p style={{ marginLeft: "20px", fontWeight: 'bold' }}>Total</p>
  <p style={{ marginRight: "70px" }}>₹{totalPrice.toFixed(2)}</p>
</div>

        <div className="button-group" style={{ marginTop: '20px' }}>
          <button className="continue-button" onClick={handleSubmit} style={{ backgroundColor: "#0b72b9", borderRadius: "10px", marginLeft: "7vw", width: "20vw" }}>
            Continue
          </button>
        </div>
      </div>
    </div>
  );
};

export default PaymentPage;
