// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* src/PaymentSuccessPage.css */
.success-page {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background: #ffffff; /* Background color */
  }
  
  .success-container {
    display: flex;
    flex-direction: column;
    padding: 20px;
    background: #97bfe6;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0,0,0,0.1);
    text-align: center;
  }
  
  .home-button {
    padding: 10px 20px;
    background-color: "green";
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  `, "",{"version":3,"sources":["webpack://./src/PaymentSuccess.css"],"names":[],"mappings":"AAAA,+BAA+B;AAC/B;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,iBAAiB;IACjB,mBAAmB,EAAE,qBAAqB;EAC5C;;EAEA;IACE,aAAa;IACb,sBAAsB;IACtB,aAAa;IACb,mBAAmB;IACnB,mBAAmB;IACnB,oCAAoC;IACpC,kBAAkB;EACpB;;EAEA;IACE,kBAAkB;IAClB,yBAAyB;IACzB,YAAY;IACZ,YAAY;IACZ,kBAAkB;IAClB,eAAe;EACjB","sourcesContent":["/* src/PaymentSuccessPage.css */\n.success-page {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    min-height: 100vh;\n    background: #ffffff; /* Background color */\n  }\n  \n  .success-container {\n    display: flex;\n    flex-direction: column;\n    padding: 20px;\n    background: #97bfe6;\n    border-radius: 10px;\n    box-shadow: 0 0 10px rgba(0,0,0,0.1);\n    text-align: center;\n  }\n  \n  .home-button {\n    padding: 10px 20px;\n    background-color: \"green\";\n    color: white;\n    border: none;\n    border-radius: 5px;\n    cursor: pointer;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
