// src/PaymentSuccessPage.js
import React from 'react';
import './PaymentSuccess.css';

const PaymentSuccessPage = () => {
  return (
    <div className="success-page">
      <div className="success-container">
        <h2>Payment Successful</h2>
        <p>Your payment has been processed successfully!</p>
        <button className="home-button" onClick={() => window.location.href = '/'} style={{backgroundColor: "green"}}>Go to Home</button>
      </div>
    </div>
  );
};

export default PaymentSuccessPage;
