



import React, { useState } from 'react';
import './CustomerInfoModal.css';
import { useNavigate, useLocation } from 'react-router-dom';
import SignUp from './images/enrollment.png'; // Importing the image

const CustomerInfoModal = () => {
  const [customerInfo, setCustomerInfo] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
  });

  const navigate = useNavigate();
  const location = useLocation();
  const { planName, monthlyPrice, annuallyPrice } = location.state || {}; // Get the selected plan's name

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCustomerInfo({ ...customerInfo, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch('https://subscription.tech-bridge.biz:8181/api/customer', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(customerInfo),
      });
      if (response.ok) {
        const data = await response.json();
        console.log('Customer Info saved:', data);
        navigate('/payment' , { state: {
           planName,
           monthlyPrice,
           annuallyPrice
          } }); 
      } else {
        console.error('Failed to save customer info', await response.text());
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
    <div className="signup-page-container">
      <div className="feature-section" style={{ textAlign: 'center' }}>
        {/* Image container to fix its position */}
        <div style={{
          width: '150px',  // Set fixed width
          height: '150px', // Set fixed height
          margin: '30px auto', // Center it with margin
          overflow: 'hidden', // Prevent layout shift
        }}>
          <img 
            src={SignUp} 
            alt="Signup Icon" 
            style={{
              width: '150%',  // Increase image size
              height: 'auto', // Maintain aspect ratio
              position: 'relative',
              left: '-25%', // Adjust position to center the image
            }} 
          />
        </div>
        <h2>Create your free Basic account</h2>
        <ul className="features-list" style={{ listStyleType: 'none', padding: 0 }}>
  <li style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
    <div style={{
      width: '20px',
      height: '20px',
      borderRadius: '50%',
      backgroundColor: 'green',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginRight: '10px'
    }}>
      <span style={{ color: 'white', fontWeight: 'bold' }}>✔</span>
    </div>
    <span style={{ fontWeight: 'bold', color: 'black' }}>Team collaboration</span>
  </li>
  <li style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
    <div style={{
      width: '20px',
      height: '20px',
      borderRadius: '50%',
      backgroundColor: 'green',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginRight: '10px'
    }}>
      <span style={{ color: 'white', fontWeight: 'bold' }}>✔</span>
    </div>
    <span style={{ fontWeight: 'bold', color: 'black' }}>Automation</span>
  </li>
  <li style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
    <div style={{
      width: '20px',
      height: '20px',
      borderRadius: '50%',
      backgroundColor: 'green',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginRight: '10px'
    }}>
      <span style={{ color: 'white', fontWeight: 'bold' }}>✔</span>
    </div>
    <span style={{ fontWeight: 'bold', color: 'black' }}>Easily track time spent by agents on tickets</span>
  </li>
  <li style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
    <div style={{
      width: '20px',
      height: '20px',
      borderRadius: '50%',
      backgroundColor: 'green',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginRight: '10px'
    }}>
      <span style={{ color: 'white', fontWeight: 'bold' }}>✔</span>
    </div>
    <span style={{ fontWeight: 'bold', color: 'black' }}>Custom Reports and Dashboards</span>
  </li>
  <li style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
    <div style={{
      width: '20px',
      height: '20px',
      borderRadius: '50%',
      backgroundColor: 'green',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginRight: '10px'
    }}>
      <span style={{ color: 'white', fontWeight: 'bold' }}>✔</span>
    </div>
    <span style={{ fontWeight: 'bold', color: 'black' }}>Multiple SLA policies & business hours</span>
  </li>
  <li style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
    <div style={{
      width: '20px',
      height: '20px',
      borderRadius: '50%',
      backgroundColor: 'green',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginRight: '10px'
    }}>
      <span style={{ color: 'white', fontWeight: 'bold' }}>✔</span>
    </div>
    <span style={{ fontWeight: 'bold', color: 'black' }}>Easily manage agent shifts across time zones</span>
  </li>
  <li style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
    <div style={{
      width: '20px',
      height: '20px',
      borderRadius: '50%',
      backgroundColor: 'green',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginRight: '10px'
    }}>
      <span style={{ color: 'white', fontWeight: 'bold' }}>✔</span>
    </div>
    <span style={{ fontWeight: 'bold', color: 'black' }}>Flexible knowledge base hierarchy FREDDY</span>
  </li>
  <li style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
    <div style={{
      width: '20px',
      height: '20px',
      borderRadius: '50%',
      backgroundColor: 'green',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginRight: '10px'
    }}>
      <span style={{ color: 'white', fontWeight: 'bold' }}>✔</span>
    </div>
    <span style={{ fontWeight: 'bold', color: 'black' }}>24x7 email support</span>
  </li>
</ul>

      </div>
      <div className="form-section">
        <div className="signup-header">
          <h2>Create your Account</h2>
        </div>
        <form className="signup-form" onSubmit={handleSubmit}>
          <div className="form-group">
            <label>First Name</label>
            <input type="text" name="firstName" value={customerInfo.firstName} onChange={handleChange} required />
          </div>
          <div className="form-group">
            <label>Last Name</label>
            <input type="text" name="lastName" value={customerInfo.lastName} onChange={handleChange} required />
          </div>
          <div className="form-group">
            <label>Email Address</label>
            <input type="email" name="email" value={customerInfo.email} onChange={handleChange} required />
          </div>
          <div className="form-group">
            <label>Phone Number</label>
            <input type="tel" name="phone" value={customerInfo.phone} onChange={handleChange} required />
          </div>
          <p className="terms">
            By proceeding, I agree to TechBridge Consultancy Services LLP <a href="https://tech-bridge.biz/privacy-policy/">Privacy Statement</a> and <a href="https://tech-bridge.biz/terms-of-services/">Terms of Service</a>.
          </p>
          <button type="submit" className="signup-button">Let's Get Started</button>
        </form>
      </div>
    </div>
  );
};

export default CustomerInfoModal;
