import React, { useState } from 'react';
import './PlanSelection.css';
import { useNavigate } from 'react-router-dom';
import CustomerInfoModal from './CustomerInfoModal';


const plans = [
  {
    name: 'Basic',
    description: 'Get going for free',
    price: '₹0/Up to 10 agents',
    // agents: 'Up to 10 agents',
    features: [
      'Integrated ticketing across email and social',
      'Ticket Dispatch',
      'Knowledge Base',
      'Ticket Trend Report',
      'Out-of-the-box analytics and reporting',
      'Choose your data center location',
      'Team collaboration',
      '24x7 email support'
    ],
    buttonText: 'START FREE TRIAL',
    billedAnnually: '₹1.00',
    billedMonthly: '₹1.00  ',
    color: '#FF5733' // Red
  },
  {
    name: 'Standard',
    description: 'Intuitive, industry-leading support for growing businesses',
    price: '₹849/agent/month, Billed annually ₹1,019.15/agent/month, billed monthly',
    // agents: '/agent/month, billed annually',
    features: [
      'Everything in Free and…',
      'Automation',
      'Collision Detection',
      '1000+ marketplace apps',
      'In-depth helpdesk report',
      'SLA management & business hours',
      'Custom Email Server',
      'Custom Ticket Views',
      ' Custom ticket fields & status',
      ' Custom SSL',
      ' Easily track time spent by agents on tickets',
      '24x5 phone support',
    ],
    buttonText: 'BUY',
    billedAnnually: '₹849',
    billedMonthly: '₹1,019.15',
    color: '#006D5B' // Green
  },
  {
    name: 'Premium',
    description: 'Advanced automation for high performance',
    price: '₹4759.15, $80/agent/month, billed annually ₹5354.15/agent/month, billed monthly',
    // agents: '/agent/month, billed annually',
    features: [
     
      'Everything in Growth and…',
'Multiple products',
'Includes up to 5000 Collaborators',
'Round-robin routing',
'Custom Roles',
'Custom objects',
'Custom Reports and Dashboards',
'Segment customers for personalized support',
'Customer journey',
'Canned forms',
'Manage versions in knowledge base',
'Community forums',
'Extendable API Limits',
'CSAT surveys & reports',
'Multiple SLA policies & business hours',
'SLA reminder & escalation',
'Multilingual knowledge base',
'Custom apps',
'Average handling Time (AHT)',
'Custom Metrics',
'Report Sharing',
'Voice - Contact Center',
    ],
    buttonText: 'BUY',
    billedAnnually: '₹4759.15',
    billedMonthly: '₹5354.15',
    color: '#0E4C92' // Blue
  },
  {
    name: 'Enterprise',
    description: 'Fully featured with bots for enterprise-level support',
    price: '₹5944.15/agent/month, billed annually ₹6994.15/agent/month, billed monthly',
    // agents: '/agent/month, billed annually',
    features: [
      'Everything in Pro and… ',
      'Unlimited products',
      'Sandbox',
      'Easily manage agent shifts across time zones',
      'Assist bot',
      'Email bot',
      'Audit log',
      'Skill-based routing',
      'Knowledge base approval workflow FREDDY',
      'Flexible knowledge base hierarchy FREDDY',
      'IP range restriction FREDDY',
      'Auto-triage FREDDY',
      'Article suggester',
      'Canned response suggester',
      'AI-powered social signals',
      'Custom Metrics',
      'Report Sharing',
      'Voice - Contact Center',
    ],
    buttonText: 'BUY',
    billedAnnually: '₹5944.15',
    billedMonthly: '₹6994.15',
    color: '#F75F86', // Pink
    
  },
];
const PlanCard = ({ plan, onStartFreeTrial }) => (
  <div className="plan-card">
    <div className="plan-header-line" style={{ backgroundColor: plan.color }}></div>
    <div className="plan-header" style={{marginTop: "1vh"}}>
    <h2 style={{textAlign: "center"}}>{plan.name}</h2>
    <p className="plan-price">{plan.price}</p>
    <p className="plan-agents">{plan.agents}</p>
    </div>
    <ul className="plan-features">
      {plan.features.map((feature, index) => (
        <li key={index}>{feature}</li>
      ))}
    </ul>
    <button className="plan-button" onClick={onStartFreeTrial} style={{ backgroundColor: plan.color }}>{plan.buttonText}</button>
  </div>
);

const PlanSelection = () => {
  // const [isModalOpen, setModalOpen] = useState(false);
  const navigate = useNavigate();

  const handleStartFreeTrial = (selectedPlanName, monthlyPrice, annuallyPrice) => {
    console.log("Selected plan:", selectedPlanName, "Monthly Price:", monthlyPrice, "Annually Price:", annuallyPrice); // Debugging log
    // window.open("/customer-info", "_blank"); // Open new tab for CustomerInfoModal
    navigate('/customer-info', { state:
       { planName: selectedPlanName ,
        monthlyPrice: monthlyPrice, 
      annuallyPrice: annuallyPrice 
       }
       }); // Navigate to CustomerInfo page in the same tab

    
  };

 

  return (
    <div  style={{backgroundColor: "#ffffff"}}>
      <nav className="navbar">
        <div className="navbar-brand">Subscription Model</div>
        <div className="navbar-menu">
          <div className="dropdown">
            <button className="dropdown-button">Choose the Product</button>
            <div className="dropdown-content">
              <a href="#">tbITSM</a>
              <a href="#">Call Center</a>
              
            </div>
          </div>
        </div>
      </nav>
      <div className="plans-intro">
        <h1 style={{ textAlign: 'center', margin: '2rem 0' }}>Choose Your Subscription Plan</h1>
      </div>
     


       <div className="plans-container">
        
        {plans.map((plan, index) => (
  <PlanCard 
    key={index} 
    plan={plan} 
    onStartFreeTrial={() => handleStartFreeTrial(plan.name, plan.billedMonthly, plan.billedAnnually)} 
  />
))}
       </div>




        {/* Footer */}
      <footer className="footer" >
        <div className="footer-content">
          <div className="footer-bottom">
          <p style={{ textAlign: 'center', color: 'white' }}>
  © 2024 <a href="https://tech-bridge.biz/" target="_blank" rel="noopener noreferrer" style={{ color: 'white', textDecoration: 'none' }}>Tech Bridge</a>. All Rights Reserved.
</p>

          </div>
        </div>
      </footer>
    </div>
  );
};


export default PlanSelection;
